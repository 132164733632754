import TabsHeader from '../../components/Headers/TabsHeader'
import { useSpeakers } from '../../data/speakers'
import { committee, executiveCommittee } from '../../data/board'

const Committee = () => {
  const { speakers } = useSpeakers();

  console.log(speakers);
  return (
    <div className='bg-gray-100 min-h-screen font-figtree pb-10'>
      <TabsHeader
      title='Committee'
      back
      extend/>

      <div className='px-5 -mt-8 flex flex-col gap-8'>
            {
               committee.map((board, index) => (
                  <div key={index} className='flex h-full flex-col justify-center bg-white rounded-lg py-4 px-6 gap-4 items-center min-w-48'>
                      <img src={board.image.toLowerCase()} alt="" className='h-36 w-36 rounded-full flex-shrink-0' />
                      <div>
                          <p className='text-xl text-center font-semibold whitespace-nowrap'>{board.name}</p>
                          <p className='text-center text-lg font-[600] text-sm'>{board.region}</p>
                          <p className='text-center'>{board.affiliation}</p>
                      </div>
                  </div>
                ))
            }
      </div>
    </div>
  )
}

export default Committee