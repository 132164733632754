import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import MainLayout from './layouts/Main';
import Schedule from './screens/Schedule/Index';
import MySchedule from './screens/MySchedule/Index';
import Profile from './screens/Profile/Index';
import Login from './screens/Auth/Login/Index';
import Register from './screens/Auth/Register/Index';
import Notifications from './screens/Notifications/Index';
import Attendees from './screens/Attendees/Index';
import Speakers from './screens/Speakers/Index';
import Gallery from './screens/Gallery/Index';
import Notification from './screens/Notifications/[notification]/Index';
import Attendee from './screens/Attendees/[attendee]/Index';
import Speaker from './screens/Speakers/[speakerId]/Index';
import Session from './screens/Session/Index';
import Index from './screens/Welcome';
import Home from './screens/Home/Index';
import Sponsors from './screens/Sponsors';
import Sponsor from './screens/Sponsors/[sponsor]';
import IndexLayout from './layouts/Index';
import Dashboard from './layouts/Dashboard';
import Auth from './layouts/Auth';
import Admin, { adminRoutes } from './screens/Admin';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import Awards from './screens/Awards';
import Committee from './screens/Commitee/commitee';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path : "/",
    element : <IndexLayout/>,
    children : [
      {
        index : true,
        element : <Index/>
      },
      {
        path : "/auth",
        element : <Auth/>,
        children : [
          {
            path : "login",
            element : <Login/>
          },
          {
            path : "register",
            element : <Register/>
          }
        ]
      },
      {
        path : "dashboard",
        element : <Dashboard/>,
        children : [
          {
            element: <MainLayout/>,
            children: [
              {
                index : true, 
                element : <Home/>
              },
              {
                path : "schedule",
                element : <Schedule/>
              },
              {
                path : "my-schedule",
                element : <MySchedule/>
              },
              {
                path : "profile",
                element : <Profile/>
              },
            ]
          },
          {
            children : [
              {
                path : "notifications",
                children : [
                  {
                    index : true, 
                    element : <Notifications/>
                  },
                  {
                    path : ":notificationId",
                    element : <Notification/>
                  }
                ]
              },
              {
                path : "attendees",
                children : [
                  {
                    index : true,
                    element : <Attendees/>,
                  },
                  {
                    path : ":attendeeId",
                    element : <Attendee/>
                  }
                ]
              },
              {
                path : "awards",
                element : <Awards/>
              },
              {
                path : "committee",
                element : <Committee/>
              },
              {
                path : "speakers",
                children : [
                  {
                    index : true,
                    element : <Speakers/>,
                  },
                  {
                    path : ":speakerId",
                    element : <Speaker/>
                  }
                ]
              },
              {
                path : "sponsors",
                children : [
                  {
                    index : true, 
                    element : <Sponsors/>
                  },
                  {
                    path : ":sponsorId",
                    element : <Sponsor/>
                  }
                ]
              },
              {
                path : "session",
                children : [
                  {
                    path : ":sessionId",
                    element : <Session/>
                  }
                ]
              },
              {
                path : "gallery",
                element : <Gallery/>
              },
            ]
          }
        ]
      },
      {
        path : "admin",
        element : <Admin/>,
        children :  adminRoutes
      }
    ]
  }
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);


serviceWorkerRegistration.register();
