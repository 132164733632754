import React from 'react'
import { 
    Carousel,
    CarouselContent,
    CarouselItem
} from '../ui/carousel';
import { executiveCommittee } from '../../data/board';
import Autoplay from 'embla-carousel-autoplay';

const Board = () => {
    const plugin = React.useRef(
        Autoplay({ delay: 2000, stopOnInteraction: true })
      )
     
  return (
    <Carousel
    plugins={[plugin.current] as any}>
        <CarouselContent className='px-3'>
            <CarouselItem className='basis-0.8/2'>
                <div className='flex h-full flex-col justify-center bg-white rounded-lg py-4 px-6 gap-4 items-center min-w-48'>
                    <img src={executiveCommittee.chair.image.toLowerCase()} alt="" className='h-36 w-36 flex-shrink-0' />
                    <div>
                        <p className='text-xl text-center font-semibold whitespace-nowrap'>{executiveCommittee.chair.name}</p>
                        <p className='text-center text-sm'>{executiveCommittee.chair.affiliation}</p>
                        <p className='text-center text-sm'>Executive Chair</p>
                    </div>
                </div>
            </CarouselItem>
            {
                executiveCommittee.boardMembers.map((board, index) => (
                    <CarouselItem key={index} className='basis-0.8/2'>
                        <div className='flex h-full flex-col justify-center rounded-full bg-white rounded-lg py-4 px-6 gap-4 items-center min-w-48'>
                            <img src={board.image.toLowerCase()} alt="" className='h-36 w-36 flex-shrink-0' />
                            <div>
                                <p className='text-xl text-center font-semibold whitespace-nowrap'>{board.name}</p>
                                <p className='text-center text-lg font-[600] text-sm'>{board.position}</p>
                                <p className='text-center'>{board.affiliation}</p>
                            </div>
                        </div>
                    </CarouselItem>
                ))
            }
            {
                executiveCommittee.boardOfGovernors.map((board, index) => (
                    <CarouselItem key={index} className='basis-0.8/2'>
                        <div className='flex h-full flex-col justify-center bg-white rounded-lg py-4 px-6 gap-4 items-center min-w-48'>
                            <img src={board.image.toLowerCase()} alt="" className='h-36 w-36 rounded-full flex-shrink-0' />
                            <div>
                                <p className='text-xl text-center font-semibold whitespace-nowrap'>{board.name}</p>
                                <p className='text-center text-lg font-[600] text-sm'>{board.region}</p>
                                <p className='text-center'>{board.affiliation}</p>
                            </div>
                        </div>
                    </CarouselItem>
                ))
            }
            <CarouselItem className='basis-0.8/2'>
                <div className='flex h-full flex-col justify-center bg-white rounded-lg py-4 px-6 gap-4 items-center min-w-48'>
                    <img src={executiveCommittee.conference.image.toLowerCase()} alt="" className='h-36 rounded-full w-36 flex-shrink-0' />
                    <div>
                        <p className='text-xl text-center capitalize font-semibold whitespace-nowrap'>{executiveCommittee.conference.name.toLowerCase()}</p>
                        <p className='text-center text-sm'>{executiveCommittee.conference.affiliation}</p>
                        <p className='text-center text-sm'>Conference Chair</p>
                    </div>
                </div>
            </CarouselItem>
        </CarouselContent>
    </Carousel>
  )
}

export default Board