export const executiveCommittee = {
  chair: {
    name: "Dr. Donna Albano",
    affiliation: "Stockton University",
    image: "/assets/Donna.png"
  },
  conference: {
    name: "Dr. GODWIN-CHARLES OGBEIDE",
    affiliation: "Metropolitan State University of Denver",
    image: "/assets/godwin.png"
  },
  boardMembers: [
    {
      name: "Dr. Stacey Smith",
      position: "Secretary",
      affiliation: "Fort Hayes State University",
      image: "/assets/Stacey.png"
    },
    {
      name: "Dr. Jeff Lolli",
      position: "Interim Treasurer",
      affiliation: "Widener University",
      image: "/assets/Jeff.png"
    },
    {
      name: "Dr. Kate Price-Howard",
      position: "Director of Marketing",
      affiliation: "Troy University",
      image: "/assets/Kate.png"
    },
    {
      name: "Dr. Robert Thompson",
      position: "Director of Professional Development",
      affiliation: "William Carey University, School of Business",
      image: "/assets/Robert.png"
    },
    {
      name: "Fran Brasseux",
      position: "CEO",
      affiliation: "International CHRIE",
      image: "/assets/Fran.png"
    },
    {
      name: "Tim Flohr",
      position: "Immediate Past Chair",
      affiliation: "University of Memphis",
      image: "/assets/tim.jpg"
    }
  ],
  boardOfGovernors: [
    {
      name: "Dr. Daniel Leung",
      region: "APacCHRIE",
      affiliation: "The Hong Kong Polytechnic University",
      image: "/assets/Daniel.png"
    },
    {
      name: "Alessia Moretti",
      region: "EuroCHRIE",
      affiliation: "César Ritz Colleges Switzerland",
      image: "/assets/Alessia.png"
    },
    {
      name: "Dr. Willie Tao",
      region: "CentralCHRIE",
      affiliation: "Oklahoma State University",
      image: "/assets/willy.png"
    },
    {
      name: "Dr. Abena A. Aidoo Hewton",
      region: "NENA",
      affiliation: "George Mason University",
      image: "/assets/Abena.png"
    },
    {
      name: "Dr. Heather Lewis",
      region: "SECSA",
      affiliation: "Troy University",
      image: "/assets/Heather.png"
    },
    {
      name: "Dr. Han Wen",
      region: "WF CHRIE",
      affiliation: "University of North Texas",
      image: "/assets/Han.png"
    }
  ]
};

export const committee = [
  {
    name: "Dr. Donna Albano",
    affiliation: "Stockton University",
    image: "/assets/Donna.png"
  },
  {
    name: "Dr. GODWIN-CHARLES OGBEIDE",
    affiliation: "Metropolitan State University of Denver",
    image: "/assets/godwin.png"
  },
    {
      name: "Dr. Kate Price-Howard",
      position: "Director of Marketing",
      affiliation: "Troy University",
      image: "/assets/Kate.png"
    },
    {
      name: "Fran Brasseux",
      position: "CEO",
      affiliation: "International CHRIE",
      image: "/assets/Fran.png"
    },
    {
      name: "Dr. Willie Tao",
      region: "CentralCHRIE",
      affiliation: "Oklahoma State University",
      image: "/assets/willy.png"
    },
    {
      name: "Dr. Han Wen",
      region: "WF CHRIE",
      affiliation: "University of North Texas",
      image: "/assets/Han.png"
    },
    {
      name: "MARY RICHARDS",
      region: "ICHRIE ESD",
      affiliation: "",
      image: "/assets/mary.png"
    },
    {
      name: "FANG SHU",
      region: "",
      affiliation: "Florida International University",
      image: "/assets/fang.png"
    }
];
