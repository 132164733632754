import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
 
import {
  Form
} from "../../../components/ui/form"
import { Button } from "../../../components/ui/button"
import CustomInput from "../../../components/CustomInput/CustomInput"
import { useMutation } from "react-query"
import { api } from "../../../lib/api"
import { useNavigate } from "react-router-dom"
import { ButtonLoading } from "../../../components/Button/CustomButton"
import BackButton from "../../../components/Nav/BackButton"
import { useToast } from "../../../components/ui/use-toast"
import { useUser } from "../../../data/user"

const Login = () => {
  const router = useNavigate();
  const { toast } = useToast();
  const { setToken } = useUser();

  const formSchema = z.object({
    email: z.string().min(2, {
      message: "Username must be at least 2 characters.",
    }),
    password: z.string().min(2, {
      message: "Username must be at least 2 characters.",
    }),
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema)
  })
 
  // 2. Define a submit handler.
  function onSubmit(values: z.infer<typeof formSchema>) {
    mutate(values)
  }

  const { isLoading, mutate, error } = useMutation({
    mutationFn: async (data: z.infer<typeof formSchema>) => {
      const res = await api.post("/api/auth/login", data);

      return res.data;
    },
    onSuccess: (data) => {localStorage.setItem("token", data); setToken(data)},
    onError: () => {toast({title : "Incorrect email or password!", variant : "destructive"})}
  })

  return (
    <div className='h-screen bg-blue-100 font-figtree flex flex-col'>
        <div className='space-y-5 flex-1'>
           <img src="/bg.jpg" className='h-full object-cover' alt="" />
        </div>

        <section className='space-y-8 bg-white w-full px-5 py-10'>
            <BackButton/>
            <div>
              <p className='text-3xl font-black text-main'>Sign In</p>
              <p>Fill the input correctly to access the app.</p>
            </div>

            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
                <CustomInput
                name="email"
                label="Email"
                type="email"
                control={form.control}/>
                <CustomInput
                name="password"
                label="Password"
                type="password"
                control={form.control}/>
                {
                  isLoading ? <ButtonLoading/> : <Button type="submit" className="w-full py-6">Proceed</Button>
                }
              </form>
            </Form>
        </section>
    </div>
  )
}

export default Login