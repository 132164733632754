import { StarFilledIcon, StarIcon } from '@radix-ui/react-icons'
import React from 'react'
import { useMutation, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom'
import { api } from '../../lib/api';
import { UserType, useUser } from '../../data/user';
import { useToast } from '../ui/use-toast';
import { MapPin, StarOffIcon, User, UserIcon } from 'lucide-react';
import { capitalizeString } from '../../lib/string';

interface Props {
    title : string;
    description : string;
    color : string;
    href : string;
    id ?: string;
    venue ?: string;
    moderator ?: string;
    sponsor ?: string;
}

const Session = ({title, description, color, href, id, venue, moderator, sponsor} : Props) => {
  const { user, updateUser, token} = useUser();
  const { toast } = useToast();

  const { mutate, isLoading } = useMutation({
    mutationKey: "addToMySession",
    mutationFn : async (sessionId:string) => {
      const res = await api.post("/api/my-session", {sessionId}, {        
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": 'application/json'
        }});
      return res.data;
    },
    onSuccess: ({user}: {user:UserType}) => {
      updateUser(user);
      toast({title : "Session added to your schedule!"})
    }
  });

  const remove = useMutation({
    mutationFn : async (sessionId:string) => {
      const res = await api.delete("/api/my-session", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": 'application/json'
        },
        data: { sessionId }
      });
      return res.data;
    },
    onSuccess: ({user}: {user:UserType}) => {
      updateUser(user);
      toast({title : "Session removed from your schedule!"})
    }
  });

  const check = user?.sessionId.find((sessionId) => sessionId === id);

  return (
      <div className='bg-white rounded-2xl px-4 flex gap-5 items-center'>
        <Link to={href} className='flex gap-4 py-4 flex-1 items-center'>
          <div className={`h-3 w-3 flex-shrink-0 rounded-full bg-${color}-500`}></div>

          <div className='flex flex-col gap-0.5'>
            <p className='font-[600] font-play capitalize'>{title.toLowerCase().replace(/wfchrie/g, "WFCHRIE").replace(/dmo/g, "DMO")}</p>
            <span className='font-light text-sm'>{sponsor}</span>
            {
              venue && <span className='flex items-center gap-2 capitalize mt-5 text-sm'><MapPin className='h-4 w-4 text-blue-500'/>{venue}</span>
            }
            {
              moderator && <span className='flex items-center gap-2 capitalize mt-3 text-sm'><UserIcon className='h-4 w-4 text-blue-500'/>{moderator}</span>
            }
          </div>
        </Link>

        {
          check ? (<StarFilledIcon onClick={(e) => {e.stopPropagation(); remove.mutate(id!)}} className='h-6 w-6 flex-shrink-0 ml-auto text-yellow-500'/>) : (<StarIcon onClick={() => mutate(id!)} className='h-6 w-6 flex-shrink-0 ml-auto'/>)
        }
      </div>
  )
}

export default Session