import React from 'react'
import SubHeader from '../../components/Headers/SubHeader'
import { AwardIcon, ScaleIcon } from 'lucide-react'
import { valuesCandles } from '../../data/values'

const Awards = () => {
  return (
    <div>
        <SubHeader
        title='Point of Honors'
        text='Eta Sigma Delta Points of Honor'/>

        <div className='bg-white w-4/5 p-4 -mt-8 mx-auto font-figtree text-sm flex items-center justify-center gap-4 rounded-xl shadow-xl'>
           <ScaleIcon className='h-10 w-10 p-2 flex-shrink-0 text-blue-500 rounded bg-blue-100'/><span className=''>6 points of honor that ESD students will recognize and shine</span>
        </div>


        <section className='px-3 py-10 space-y-7 font-figtree'>
            {
                valuesCandles.map(({ theme, description, Icon }, index) => (
                    <div key={index} className="bg-white p-5 shadow space-y-4 rounded-lg">
                        <div className='flex justify-between items-center'>
                            <p className='text-lg text-yellow-500 font-semibold text-lg'>{theme}</p>
                            <Icon className='h-10 w-10 text-blue-500'/>
                        </div>
                        <p>{description}</p>
                    </div>
                ))
            }
        </section>
    </div>
  )
}

export default Awards