import moment from "moment";
import { create } from "zustand";

export interface sessionType {
  id: string;
  title: string;
  startTime: string;
  endTime: string;
  date: Date;
  description: string;
  color: string;
  image: string | null;
  speakers ?: string;
  venue : string;
  sponsor ?: string;
  moderator ?: string;
  createdAt: Date;
}

interface sessionStore {
    sessions : sessionType[];
    loading : boolean;
    currentDate : number;
    updateSessions : (data: sessionType[]) => void;
    updateLoading : (loading:boolean) => void;
    setCurrentDate : (date:number) => void;
}

export const useSessions = create<sessionStore>()(set => ({
    sessions : [],
    loading : true,
    currentDate : moment("2024-02-01").date(),
    updateSessions : (data:sessionType[]) => set(state => ({sessions : data})),
    updateLoading : (loading:boolean) => set(state => ({loading : loading})),
    setCurrentDate: (date:number) => set(state => ({currentDate : date})) 
}));
