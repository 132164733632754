import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Outlet } from 'react-router-dom'
import { Toaster } from '../components/ui/toaster'

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useUser } from '../data/user';
import { AddToHomeScreenDialog } from '../AddToHomeScreen';


function ScrollToTop() {
  const { pathname } = useLocation();
  const { setToken, token } = useUser();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const token = localStorage.getItem("token")
    if(token) setToken(token)
  }, []);

  return null;
}


const IndexLayout = () => {
    const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
        <ScrollToTop/>
        <Outlet/>
        <Toaster />
        <AddToHomeScreenDialog/>
    </QueryClientProvider>
  )
}

export default IndexLayout