import React from 'react'
import SubHeader from '../../../components/Headers/SubHeader'
import { useSpeakers } from '../../../data/speakers'
import { useParams } from 'react-router-dom'
import Session from '../../../components/Cards/Session'
import { url } from '../../../lib/api'
import { image } from '../../../lib/image'

const Speaker = () => {
  const { speakerId } = useParams();
  const { speakers } = useSpeakers();

  const speakerData = speakers.find(({id}) => id === speakerId);

  if(!speakerData) return <></>

  return (
    <section className='font-figtree bg-gray-100 min-h-screen'>
      <SubHeader
      title='Speakers'/>

      <div className='flex justify-center -mt-16'>
        <img src={image(speakerData.image)} alt="img" className='h-40 w-40 object-cover rounded' />
      </div>

      <section className='space-y-5 px-5 py-5'>
          <div className='text-center'>
            <p className='font-semibold text-xl'>{speakerData.name}</p>
            <span className='capitalize'>{speakerData.title}</span>
          </div>

          <div style={{whiteSpace : "pre-line"}}>
            {speakerData.bio}
          </div>


          <div className='space-y-3'>
            <p className='font-semibold text-lg'>Session</p>

            <div className='flex flex-col gap-4'>
              {
                  speakerData.sessions.map((session) => (
                    session && (
                      <Session
                      color={session.color}
                      title={session.title}
                      description={session.description}
                      href={`/dashboard/session/${session.id}`}/>
                    )
                  ))
              }
            </div>
          </div>
      </section>
    </section>
  )
}

export default Speaker