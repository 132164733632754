import { CrownIcon, GlobeIcon, HeartHandshake, LightbulbIcon, ScaleIcon, TrophyIcon } from "lucide-react";

export const valuesCandles = [
    {
      theme: "Creativity",
      description: "We light this candle in recognition of Creativity. We realize that our ability to think creatively will affect the future of our professions. Our innovation will lead to better decisions and outcomes.",
      Icon: LightbulbIcon
    },
    {
      theme: "Diversity-Equity-Inclusion",
      description: "We light this candle to demonstrate our commitment to diversity, equity, and inclusion. We respect people, value diversity, and are committed to equality for everyone. By valuing our differences, creating equitable opportunities for everyone, and welcoming all in the true spirit of hospitality, we can be role models for others.",
      Icon: GlobeIcon
    },
    {
      theme: "Ethics",
      description: "We light this candle to establish the commitment we have to Ethics. The consideration of Ethics guides our personal and professional decision-making. May this final candle brilliantly light the way for each of us throughout our lives.",
      Icon: ScaleIcon
    },
    {
      theme: "Excellence",
      description: "We light this candle to represent our continuous pursuit of Excellence in all we do. Through the quest for Excellence, we pledge to uphold high standards for both academic and professional achievement.",
      Icon: TrophyIcon
    },
    {
      theme: "Leadership",
      description: "We light this candle as a hallmark of Leadership. A leader is like a light held high that brightens the way for others. Let us each pledge that in our personal and professional relationships, we will demonstrate the qualities of Leadership.",
      Icon: CrownIcon
    },
    {
      theme: "Service",
      description: "We light this candle to acknowledge the importance of Service. After all, it is service that makes the hospitality industry unique. May our concern for others help promote our professional success and inspire us to lead lives of service.",
      Icon: HeartHandshake
    }
  ];
  