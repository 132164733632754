import React from 'react'

import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableFooter,
    TableHead,
    TableHeader,
    TableRow,
  } from "../../components/ui/table"
import { useMutation, useQuery } from 'react-query';
import { useUser } from '../../data/user';
import { api } from '../../lib/api';
import { attendeesType } from '../../data/attendees';
import { Button } from '../../components/ui/button';
import { useToast } from '../../components/ui/use-toast';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import CustomInput from '../../components/CustomInput/CustomInput';
import { Form } from '../../components/ui/form';
import { ButtonLoading } from '../../components/Button/CustomButton';
  
const formSchema = z.object({
  firstName : z.string(),
  lastName : z.string(),
  email : z.string().optional(),
  company : z.string().optional(),
})

  
const Attendee = () => {
  const { token } = useUser();
  const { toast } = useToast();


  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema)
  })

  // 2. Define a submit handler.
  function onSubmit(values: z.infer<typeof formSchema>) {
      // Do something with the form values.
      // ✅ This will be type-safe and validated.
      mutate(values)
    }

  const { data, isSuccess, refetch } = useQuery({
    queryKey : ['attendees'],
    queryFn : async () => {
      const res = await api.get("/api/attendee", {
        headers : {
          Authorization : `Bearer ${token}`,
          "Content-Type": 'application/json'
        }
      });

      const notifications:attendeesType[] = res.data
      return notifications
    },
    enabled : (token !== null),
  });

  const { mutate, isLoading, error } = useMutation({
    mutationFn: async (data:z.infer<typeof formSchema>) => {
     const res = await api.post("/api/attendee", data, {
        headers : {
          Authorization : `Bearer ${token}`,
          "Content-Type": 'application/json'
        }
      });

      return res.data;
    },
    onSuccess: () => {
      refetch();
      toast({
        title : "Attendee created successfully!"
      })
    }
  });


  const removeItem = useMutation({
    mutationFn: async (id:string) => {
      const res = await api.delete("/api/attendee", { 
        headers : {
          Authorization : `Bearer ${token}`,
          "Content-Type": 'application/json'
        }, 
        data : { id }
      });

      return res.data;
    },
    onSuccess: () => {
      toast({
        title: "Attendee removed successfully!"
      });
      refetch();
    }
  });

  return (
    <div>
            <section className="space-y-5 p-4 sm:p-10 border rounded-xl">
        <p>New Attendee</p>


        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <div className='grid grid-cols-2 gap-4'>
              <CustomInput
              name="firstName"
              control={form.control}
              label="First Name"
              />
              <CustomInput
              name="lastName"
              control={form.control}
              label="Last Name"
              />
            </div>
            <CustomInput
            name="email"
            control={form.control}
            label="Email"
            />
            <CustomInput
            name="company"
            control={form.control}
            label="Company"
            />
            {
              isLoading ? <ButtonLoading/> : <Button type="submit">Submit</Button>
            }
          </form>
        </Form>
      </section>

        <Table>
        <TableCaption>A list of attendees.</TableCaption>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[100px]">S/N</TableHead>
            <TableHead>First Name</TableHead>
            <TableHead>Last Name</TableHead>
            <TableHead>Email</TableHead>
            <TableHead>Action</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {isSuccess && data.map((attendee, index) => (
            <TableRow key={attendee.id}>
              <TableCell className="font-medium">{index+1}</TableCell>
              <TableCell>{attendee.firstName}</TableCell>
              <TableCell>{attendee.lastName}</TableCell>
              <TableCell>{attendee.email}</TableCell>
              <TableCell><Button onClick={() => {
                    const sure  = window.confirm("Are you sure you want to delete this?")
                    sure && removeItem.mutate(attendee.id)
                    }} variant={"destructive"}>Delete</Button></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default Attendee