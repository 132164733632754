import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

const Index = () => {
    const router = useNavigate();

    useEffect(() =>{
      const token = localStorage.getItem("token");
  
      if(token){
          router("/dashboard");
      }
    }, []);

  return (
    <div className='h-screen bg-blue-100 font-figtree flex flex-col'>
        <div className='space-y-5 flex-1 bg-green-500'>
           <img src="./bg.jpg" className='h-full object-cover' alt="" />
        </div>

        <section className='space-y-8 bg-white w-full px-5 py-20'>
            <p className='font-black text-4xl text-gray-600'>Welcome to <br /><span className='text-main'>ICHRIE Eta Sigma Delta (ESD)</span></p>

            <p> 2024 Eta Sigma Delta (ESD) Undergraduate Student Research Symposium
                February 20-21, 2024. The conference will take place on February 20-21, 2024 | Miami, Florida, USA.
            </p>

            <div className='rounded-full grid grid-cols-2 h-14'>
                <Link to={'/auth/login'}>
                    <div className='bg-main flex-1 text-white flex items-center justify-center text-lg text-center rounded-full h-full'>
                        Sign In
                    </div>
                </Link>
                <Link to={'/auth/register'}>
                <div className='h-full text-main flex-1 flex items-center justify-center text-lg text-center '>
                    Register
                </div>
                </Link>
            </div>
        </section>
    </div>
  )
}

export default Index